import { memo } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Styles from './Logo.module.scss';

import HireDigital from './svg/hd.inline.svg';

const Logo = memo(({ className, type, site = '1', ...props }) => {
  const logoSize = site === '2' ? Styles.hdLogo : Styles.hdMarketingLogo;
  return <HireDigital className={classNames(logoSize, className, type)} {...props} />;
});

Logo.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
};

Logo.Type = {
  WHITE: Styles.white,
  BLACK: Styles.black,
  BLUE: Styles.blue,
  DARKBLUE: Styles.darkblue,
  DARKGRAY: Styles.darkgray,
};

export default Logo;
