import { Fragment, useState, useEffect } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';

import Styles from './UserMasonry.module.scss';

const MasonryType = {
  STANDARD: 0,
  TALENT: 1,
  COMPANY: 2,
};

const Item = dynamic(() => import('./MasonryItem'), { ssr: false });
const TalentItem = dynamic(() => import('./MasonryItemTalent'), { ssr: false });

const Container = ({ items, isMobile, type, bottom, className, specialTile: CustomTile }) => {
  const newItems = [...items];
  const [itemList, setItemList] = useState(newItems);
  const [mobile, setMobile] = useState(false);

  useEffect(() => {
    setMobile(isMobile);
  }, [isMobile]);

  useEffect(() => {
    if (CustomTile && newItems.includes(CustomTile) === false) {
      newItems.splice(1, 0, CustomTile);
      setItemList([...newItems]);
    }
  }, [CustomTile]);

  const ItemTile = type === MasonryType.STANDARD ? Item : TalentItem;

  return (
    <Fragment>
      <div
        className={classNames(
          mobile ? Styles.scroll : Styles.portfolioList,
          type === MasonryType.TALENT && Styles.talentPortfolioList,
          type === MasonryType.COMPANY && Styles.companyPortfolioList
        )}>
        <div className={classNames(Styles.spacer, CustomTile && Styles.smallSpacer)} />
        {itemList.map((item, index) =>
          item?.uuid ? <ItemTile key={index} item={item} /> : <CustomTile key={index} />
        )}
        {mobile && <div style={{ flex: '0 0 1px' }} />}
      </div>
      {mobile && <div className={Styles.removeScroll} />}
    </Fragment>
  );
};

Container.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  isMobile: PropTypes.bool,
  bottom: PropTypes.bool,
  className: PropTypes.string,
};
Container.MasonryType = MasonryType;

export default Container;
