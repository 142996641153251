import { useState, useEffect } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Container from './MasonryContainer';
import Styles from './UserMasonry.module.scss';

const UserMasonry = ({
  users,
  bottom,
  type = Container.MasonryType.STANDARD,
  specialTile,
  className,
}) => {
  const [isMobile, setIsMobile] = useState(false);
  /**
   * Define if isMobile is mobile
   */
  function setSliderType() {
    if (typeof window !== 'undefined') {
      if (window.innerWidth < 599) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    }
  }

  useEffect(() => {
    window.addEventListener('resize', setSliderType);
    setSliderType();
    return function removeListener() {
      window.removeEventListener('resize', setSliderType);
    };
  }, []);

  return (
    <div
      className={classNames(
        Styles.container,
        className,
        type === Container.MasonryType.COMPANY && Styles.companyContainer
      )}>
      <Container
        items={users}
        type={type}
        isMobile={isMobile}
        bottom={bottom}
        specialTile={specialTile}
      />
    </div>
  );
};

UserMasonry.propTypes = {
  users: PropTypes.array,
  bottom: PropTypes.bool,
  type: PropTypes.number,
};
UserMasonry.MasonryType = Container.MasonryType;

export default UserMasonry;
