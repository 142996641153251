import Styles from './Styles.module.scss';
import ButtonLink from '@hiredigital/ui/Button/NextButtonLink';
import Button from '@hiredigital/ui/Button';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const HeroButton = ({ className, to, href, children, ...props }) => {
  let Component;
  if (to || href) {
    Component = ButtonLink;
  } else {
    Component = Button;
  }
  return (
    <Component
      className={classNames(Styles.button, className)}
      href={to || href || undefined}
      {...props}>
      {children}
    </Component>
  );
};

HeroButton.Type = {
  WHITE: ButtonLink.Type.WHITE,
  BLUE: ButtonLink.Type.BLUE,
  DARKBLUE: ButtonLink.Type.DARKBLUE,
  BLUE_OUTLINE: ButtonLink.Type.BLUE_OUTLINE,
  WHITE_OUTLINE: ButtonLink.Type.WHITE_OUTLINE,
};

HeroButton.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  to: PropTypes.string,
  type: PropTypes.string,
  children: PropTypes.node,
};

export default HeroButton;
